import React, {useEffect, useRef, useState} from "react";
import "./styles.scss";
import CardContainer from "../CardContainer";
import pagaygana from "../../assets/images/pagaygana/banner.png";
import http from '../../https/api';

//mini componentes
import RenderSantasIcon from "./RenderIcons";
// import CodeComponent this replace with popup notification from "./CodeComponent";
import PromoComponent from "./PromoComponent";
import { encryptData, getCookie } from "../../util/util";

//TEST AREA
// function getQueryParam(param) {
//   const params = new URLSearchParams(window.location.search);
//   return params.get(param);
// }

//FIN TEST AREA

// Componente de paga y gana en el portal del cliente
export const SantiPuntos =({cedula, estilos, cuenta, pais}) => {


  //elementos interactivos
  const buttonElement = useRef();
  const containerSantas = useRef(0);
  const containerActions = useRef(0);
  //state declarations 
  const [santipuntosState, setSantipuntosState] = useState(0);

  const [buttonText, setButtonText] = useState('QUIERO JUGAR');
  const [isHidden, setIsHidden] = useState(false);
  const [codePromo, setCodePromo] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [showComponentCol, setShowComponent] = useState({
    "showPan":false,
    "showCol":false
  });
  const [promoId, setPromoId] = useState(pais == "JA" ? "989619" : "978332");
  const [loadCampaing, setLoadCampaing] = useState(false);

  // let [cedulaForTest, setCedulaForTest] = useState("");
  // let [testEnviroment, setTestEnviroment] = useState(false);

  // useEffect(() => {
  //   cedula = getQueryParam("cedula");
  // }, [])

  //Funcion para validar sí el cliente tiene el saldo al día o tiene saldo vencido
  useEffect(() => {
async function getComponetState() {
  await fetch("https://cdn.shopify.com/s/files/1/0516/5478/7271/files/portalComponent.json?"+Math.random())
  .then(response => response.json())
  .then(data => {
    setShowComponent({
      showCol: data.CO.showComponent,
      showPan: data.PA.showComponent
    });
    })
}
    
async function getStatusSantiPunto(documento) {
    let url = process.env.REACT_APP_PUNTOS;
    let token = getCookie('token');
    let formData = {
      cedula: encryptData(documento),
      company: pais
    }
    try {
        let response = await fetch(`${url}puntos_cliente`, { method: 'POST', body: JSON.stringify(formData), headers: { 'Authorization': `Bearer ${token}` } });

        if (response.status !== 200) {
            throw new Error('Request failed with status ' + response.status);
        }

        let data = await response.json(); 
        setSantipuntosState(data.puntos);
        setLoadCampaing(true)
        setButtonText("QUIERO JUGAR");
        if (pais === "JA" ) {
          if (data.puntos >= 5 && data.aplica == "S" ) {
            getCode(documento, "JA");
            setIsActive(true);
            setButtonText('COMIENZA A JUGAR');
          } else {
              // buttonElement.setAtt = true;
          }
        } else if (pais === "JP" ) {
          if (data.aplica == "S" ) {
            getCode(documento, "JP");
            setIsActive(true);
          } else {
              // buttonElement.setAtt = true;
          }
        }
          
    } catch (error) {
        console.error(error)
    }
}

async function getCode(documento, pais) {
    let conmpanyIdForPagaYGana = pais;
    let codigoCampana = promoId;
    const url = `https://x820g2be4k.execute-api.us-east-1.amazonaws.com/prd/v1/easypromo/validate-access-code/${conmpanyIdForPagaYGana}/${documento}/${codigoCampana}?validate_points=true`;

    const headers = { 'Content-Type': 'application/json' };
    try {
        let response = await fetch(url, { method: 'GET', headers: headers });

        if (response.status !== 200) {
            throw new Error('Request failed with status ' + response.status);
        }

        let data = await response.json();

        if (data.statusCode === 200) {
            let code = data.body.data.access_code;
            setCodePromo(code);
        }
    } catch (error) {
        console.error('Error:', error);
    }
} 
getComponetState();
getStatusSantiPunto(cedula);
}, [santipuntosState]);

  return (
    showComponentCol.showCol && pais === "JA" &&
    <>
    {isHidden && <PromoComponent code={codePromo} stateIsActive={setIsHidden} campaing={promoId} />}
    <div className={`contenedor-tarjeta mt-0`}>
      <div className="container p-0 d-flex justify-content-center">
        <div className="row rounded border-0 santipunto-container w-100 p-0 overflow-hidden position-relative">
                <div className="container-bg-navidad w-100">
                  <picture className="m-0 p-0">
                    <img className="main-bg text-center" src="https://cdn.shopify.com/s/files/1/0519/2695/8235/files/banner-portal-cliente.png?v=1736349147" alt="" />
                  </picture>
                    <p className="santipunto-parrafo" style={{color: '#ffffff'}}>Por cada <br /> 
                    <span> <b>$30.000 </b>pagados</span> 
                    <br />ganas <b>1 punto</b>.</p>

                    <div ref={containerSantas} className="container-santas d-flex justify-content-center">
                      <RenderSantasIcon santipuntos={santipuntosState} />
                    </div>
                    <div ref={containerActions} className={`container-actions ${isHidden ? 'd-none' : ''}`}>
                    </div>
                </div>
                <div className="container-information d-flex flex-column align-items-center mt-0">
                    <p className="mt-4 px-md-5 px-sm-2 px-0 text-lg">
                      <span className="main-text"><b>ACUMULA 5 PUNTOS</b></span>
                     <br />y podrás participar por un regalo para alegrar tu inicio de año.<br />
                      {/* <span className="main-text d-block mt-2">Tus puntos acumulados son:</span> <br /> */}
                      {/* <span className="puntos-acumulados"> */}
                        {/* <b>{ santipuntosState > 0 ? santipuntosState : 0 }</b> */}
                      {/* </span> */}
                      <br />
                      
                        {/* { !isActive ? <span className="estas-aldia opaco"> <b> Debes estar al día</b></span> : 
                        <span className="estas-aldia"> <b> Estas al día</b> </span> } */}
                        </p>

                    <button 
                      ref={buttonElement} 
                      className={`fetch-santipuntos mt-0 ${isActive ? 'success' : ''}`}
                      onClick={() => {
                        if (!isActive) return;
                        setIsHidden(true);
                        setButtonText('Juego comenzado');
                      }}
                    >
                      { buttonText || 'Comienza a jugar' }
                      <img className="h-100" src="https://cdn.shopify.com/s/files/1/0516/5478/7271/files/icon-clic.svg?v=1718142233" alt="" />
                    </button>
                 

                    <p className="mb-0">Los puntos se actualizan cada 24 horas.</p> 
                    <p className="terms mt-0">Actividad válida del 13 de enero al 13 de febrero del 2025.</p>
                </div>
                {/* <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/portal-bottom-image.svg" alt="" /> */}

        </div>
    </div>
    </div>
    </>
  )
}
